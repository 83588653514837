import {
  Autocomplete,
  Box,
  Button,
  Container,
  createFilterOptions,
  FormControl,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AgreementSyncGrid from "../components/AgreementSyncGrid";
import SyncIcon from "@mui/icons-material/Sync";
import { getDealerRegistrationList } from "../services/dealerRegisterServices.js";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../AuthContext.jsx";
import axios from "../services/configs/axiosConfig.js";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`agmclm-tabpanel-${index}`}
      aria-labelledby={`agmclm-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `agmclm-tab-${index}`,
    "aria-controls": `agmclm-tabpanel-${index}`,
  };
}

const AgreementsSyncHome = () => {
  const { group } = useContext(AuthContext);
  const [selectedDealer, setSelectedDealer] = useState(
    JSON.parse(localStorage.getItem("dealer"))
  );
  const [dealers, setDealers] = useState([]);
  const [enableSyncBtn, setEnableSyncBtn] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.dealerId + option.dealerName,
  });
  const loadDealers = () => {
    console.log("Loading dealers for agreement sync.....");
    if (group !== "jbw-admin") {
      console.log("not loading dealer list as login user is not admin");
      return;
    }
    getDealerRegistrationList()
      .then((res) => {
        const data = res.data;
        const statusCode = res.status;
        if (statusCode === 200) {
          if (data && data.length > 0) {
            const nonAdminDealers = data.filter(
              (dt) => dt.isActive !== 0 && dt.dealerId !== 0
            );
            console.log("Non admin dealers list :", nonAdminDealers);
            setDealers(nonAdminDealers);
            if (nonAdminDealers.length > 0) {
              setSelectedDealer(nonAdminDealers[0]);
            }
          }
        }
      })
      .catch((err) => {
        console.error("Internal server error : ", err);
      });
  };

  useEffect(() => {
    loadDealers();
  }, []);

  // const handleDealerChange = (event) => {
  //   const dealerId = event.target.value;
  //   console.log("selected dealer", dealerId);
  //   if (!dealerId) return;
  //   setSelectedDealer(dealerId);
  // };

  const syncDataHandler = async () => {
    const url = `agreementsync/${selectedDealer.dealerId}/spcall`;
    //stDataSyncSpCall(selectedDealer.dealerId)
    try {
      axios
        .get(url)
        .then(
          (res) => {
            if (res.status === 200) {
              console.log("Agreement sync success");
            }
          },
          (err) => {
            console.log("unable to sync agreements service", err);
          }
        )
        .catch((err) => {
          console.error("Internal server error : ", err);
        });
    } catch (error) {
      console.error("Internal server error : ", error);
    }

    setEnableSyncBtn(true);
  };

  const emptyDataListHandle = (data) => {
    setEnableSyncBtn(data);
  };

  const tabDisply = () => {
    return (
      <Box
        sx={{
          maxWidth: "100%",
          padding: 2,
          boxShadow: 4,
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="Agreements Sync Tabs"
          >
            <Tab label="UnApproved" {...a11yProps(0)} />
            <Tab label="Approved" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <AgreementSyncGrid
            dealer={
              group === "jbw-admin"
                ? selectedDealer.dealerId
                : selectedDealer.id
            }
            dealerName={selectedDealer.dealerName}
            onEmptyDataList={emptyDataListHandle}
            tabType="unapproved"
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <AgreementSyncGrid
            dealer={
              group === "jbw-admin"
                ? selectedDealer.dealerId
                : selectedDealer.id
            }
            dealerName={selectedDealer.dealerName}
            onEmptyDataList={() => emptyDataListHandle(false)}
            tabType="approved"
          />
        </CustomTabPanel>
      </Box>
    );
  };

  return (
    <Box component="main">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={12}>
            <Box
              sx={{
                maxWidth: "100%",
                padding: 2,
                boxShadow: 4,
                borderRadius: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      padding: 1,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h6" component="h6">
                      Agreements Sync <br />
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ color: "#a8a8a8d4" }}
                      ></Typography>
                    </Typography>
                    {group === "jbw-admin" && selectedDealer && (
                      <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <Autocomplete
                            freeSolo
                            id="dealer-search-auto"
                            value={selectedDealer}
                            clearOnBlur={true}
                            //disableClearable
                            getOptionLabel={(option) =>
                              `${option.dealerId}-${option.dealerName}`
                            }
                            filterOptions={filterOptions}
                            options={dealers}
                            onChange={(event, value) => {
                              emptyDataListHandle(false);
                              console.log(
                                "Auto complete delaer value :",
                                value
                              );
                              if (!value) return;
                              setSelectedDealer(value);

                              //handleDealerChange(event);
                            }}
                            sx={{
                              width: 350,
                              borderRadius: "0px",
                              ml: 5,
                              mt: -1,

                              ".MuiOutlinedInput-root": {
                                borderRadius: "0px",
                                p: "2px",
                                "&:hover": {
                                  borderRadius: 0,
                                  borderColor: "red",
                                  borderWidth: 5,
                                },
                              },
                              "input[type='search']::-webkit-search-cancel-button":
                                {
                                  display: "none",
                                },
                            }}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  ".MuiInputBase-root": {
                                    borderRadius: "0px",
                                    borderColor: "#e0e0e0",
                                    ":hover": {
                                      borderColor: "#e0e0e0",
                                    },
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    legend: {
                                      marginLeft: "30px",
                                    },
                                  },
                                  "& .MuiInputLabel-outlined": {
                                    paddingLeft: "35px",
                                  },
                                }}
                                {...params}
                                label=""
                                placeholder="Search Dealer"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon style={{ fill: "#ffc400" }} />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />

                          {/* <InputLabel id="dealer-select-small-label">
                            Dealers
                          </InputLabel>
                          <Select
                            labelId="dealer-select-small-label"
                            id="dealer-select-small"
                            value={selectedDealer}
                            label="Dealers"
                            onChange={handleDealerChange}
                          >
                            {dealers.map((ad) => {
                              return (
                                <MenuItem key={ad.id} value={ad}>
                                  {ad.dealerId} - {ad.dealerName}
                                </MenuItem>
                              );
                            })}
                          </Select> */}
                        </FormControl>
                      </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    {enableSyncBtn && (
                      <Box>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{
                            borderRadius: "50px",
                          }}
                          startIcon={<SyncIcon />}
                          onClick={syncDataHandler}
                        >
                          Sync Data
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Box
                  sx={{
                    maxWidth: "100%",
                    padding: 1,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {selectedDealer && tabDisply()}
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AgreementsSyncHome;
