import axios from "./configs/axiosConfig";

export const getAgeementMatchDataList = async (dealerId,datatype, page, limit,tabType) => {
  const url = `agreementsync/${dealerId}?page=${page}&limit=${limit}&type=${datatype ? datatype: 'all'}&tabtype=${tabType? tabType:'unapproved'}`;
  return await axios.get(url);
};

export const updateAgeementMatchLocation = async (
  agreementMatchId,
  locationData
) => {
  const url = `agreementsync/${agreementMatchId}/location`;
  return await axios.put(url, locationData);
};

export const approveAgreements = async (agreementMatchIds, approvedBy) => {
  const url = `agreementsync/approve`;
  return await axios.put(url, { agreementMatchIds, approvedBy });
};

export const stDataSyncSpCall = async (dealerId) => {
  const url = `agreementsync/${dealerId}/spcall`;
  return await axios.get(url);
};

export const agreementSyncFileUpload = async (dealerId,data) => {
  const url = `/agreementsync/${dealerId}/fileupload `;
  return await axios.post(url,data);
};
