import exportFromJSON from "export-from-json";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getAgeementMatchDataList } from "../services/agreementMatchDataService";
import { Typography } from "@mui/material";

const AgreementSyncDataDownload = ({
  datatype,
  dealerId,
  openDownload,
  onClose,
  dealerName,
  userName,
}) => {
  console.log("AgreementSyncDownlaod", openDownload);

  const [open, setOpen] = useState(openDownload);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
        return;
      }
      onClose();
  }
  const [jsonData, setJsonData] = useState(true);

  const fetchData = () => {
    getAgeementMatchDataList(dealerId,datatype, 0, -1)
      .then((res) => res.data)
      .then((res) => {
        setJsonData(res.data);
        if (res.data && res.data.length > 0) {
            
          createDownloadFile(res.data);
        }else{
            setJsonData(false);
            setTimeout(()=>{handleClose();},2000)
            
        }
      })
      .catch((err) => {
        console.error("Internal server error : ", err);
      });
  };

  const createDownloadFile = (data) => {
    console.log("createDownloadFile",data.length);   
    const fileName = `${dealerId}-${userName}-agreement-sync-export-${datatype === "all" ? "All" : datatype === 'unapproved' ? "UnApproved": "NoLocations"}-${new Date().getTime()}`;
    const exportType = exportFromJSON.types.csv;
    const fields = [
      "dealerId",
      "agreementId",
      "locationId",
      "tenantId",
      "productCode",
      "pdfUrl",
      "jbwPhoneNumber",
      "jbwEmail",
      "jbwAddressLine1",
      "jbwAddressLine2",
      "jbwCity",
      "jbwState",
      "jbwZip",
      "jbwPurchaseDate",
    ];
    exportFromJSON({ data, fileName, fields, exportType });

    handleClose();
  };

  useEffect(() => {
    console.log("calling fetch");
    if (openDownload) {
      fetchData();
    }
  }, [openDownload]);

  //   useEffect(()=>{
  //     console.log("jsondata",jsonData)
  //     if(jsonData){
  //         createDownloadFile(jsonData)
  //     }

  //   },[jsonData.length>0])

  return (
    <Dialog
      open={openDownload}
      onClose={ handleClose}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
      <Typography variant="subtitle1">
        Downloading Dialog {datatype === "all" ? "All" : datatype === 'unapproved' ? "Un Approved": "No Locations"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            <Typography variant="caption" display="block">
            {!jsonData ?  "Sorry No Data Found Closing Dialog":` Please wait Download data for ${dealerName} is in progress......` }
            </Typography>
          
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AgreementSyncDataDownload;
