import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { throttle } from "lodash";
import React, { useState } from "react";
import { getStLocationSearchWithDealer } from "../services/stlocationService";

const STLocationSearch = ({
  dealer,
  type,
  onLocationSelected,
  searchValue,
}) => {
  const [value, setValue] = useState(searchValue);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading,setLoading] = useState(false);

  const fetch = React.useMemo(
    () =>
      throttle((request) => {
        if (dealer && request?.input.length > 2) {
          setLoading(true);
          const getData = getStLocationSearchWithDealer(
            dealer,
            request?.input,
            type
          );
          getData.then((res) => {
            console.log("location data memo :", res.data);
            setOptions(res.data);
            setLoading(false);
            return res.data;
          });
        }
      }, 200),
    [dealer, type]
  );

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // useEffect(() => {
  //   onLocationSelected(value);
  // }, [value]);

  const onValueSelect = (data) => {
    console.log("selected Data", data);
    onLocationSelected(data);
  };

  return (<>
    <Autocomplete
      id="st-location-search"
      sx={{ width: 250, borderRadius: 50 }}
      getOptionLabel={(option) => {
        return `${option.street ? option.street + "," : ""}
              ${option.unit ? option.unit + "," : ""}
              ${option.city ? option.city + "," : ""}
              ${option.state ? option.state + "," : ""}
              ${option.zip ? option.zip : ""}.`;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={loading}
      loadingText={<Typography sx={{color:'orange'}} variant="caption" display="block">Loading Locations by {type==="locationid" ? 'ID':'Address'}...</Typography>}
      isOptionEqualToValue={(option, value) => option.street === value.street}
      noOptionsText={
        <Typography sx={{color:'red'}} variant="caption" display="block" >No Location Found , Please enter Service Titan Location {type==="locationid" ? 'ID':''} </Typography>
      }
      onChange={(event, newValue) => {
        console.log("newValue", newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onValueSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontSize: 12 }}
          variant="standard"
          label="Location Search"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ fontSize: 12 }} {...props} key={option.id}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <p>ST Id : {option.locationId}</p>
            <p>
              {`${option.street ? option.street + "," : ""}
              ${option.unit? option.unit + "," : ""}
              ${option.city ? option.city + "," : ""}
              ${option.state ? option.state + "," : ""}
              ${option.zip ? option.zip : ""}.`}
            </p>
          </Stack>
        </Box>
      )}
    />
   
    </>
  );
};

export default STLocationSearch;
