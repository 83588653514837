import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const  AgreementSyncDownloadMenu = ({onDownload,disabled}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
   
    setAnchorEl(null);

  };

  const onDownloadSelect = (type)=>{
    onDownload(type)
    handleClose();
  }

  return (
    <div>
      <Button
        id="agr-sync-donwload-btn"
        aria-controls={open ? "agr-sync-donwload-menu" : undefined}
        aria-haspopup="true"
         color="primary"
        aria-expanded={open ? "true" : undefined}
        sx={{ mb: 1 }}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
      >
        Download
      </Button>
      <StyledMenu
        id="agr-sync-donwload-menu"
        MenuListProps={{
          "aria-labelledby": "agr-sync-donwload-btn",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={()=> onDownloadSelect('all')} >
          <EditIcon />
          Download All
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={()=> onDownloadSelect('unapproved')} >
          <FileCopyIcon />
          Download UnApproved
        </MenuItem>
        
        <MenuItem onClick={()=> onDownloadSelect('nolocation')} >
          <ArchiveIcon />
          Download Missing Location
        </MenuItem>
      </StyledMenu>
    </div>
  );
};


export default AgreementSyncDownloadMenu