import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { agreementSyncFileUpload } from '../services/agreementMatchDataService';
import { Input, Typography } from '@mui/material';


const AgreementSyncFileUpload = ({uploadOpen,onClose,dealerId,dealerName}) => {

  
    const handleClose = (event,reason) => {
        if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return;
          }
      onClose()
    };

    const [file, setFile] = React.useState(null);
    const [uploadUrl, setUploadUrl] = React.useState("");
    const [msg,setMsg]=React.useState("");
    const [status,setStatus] = React.useState(false);
    const [closeBtn,setCloseBtn] = React.useState(false);
    const [loading,setLoading] = React.useState(false);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
      try {

        setCloseBtn(true);
        setLoading(true);
        setMsg("");
        if (!file) {
            setMsg("Please select a file!");
            return;
        }

        // Convert file to Base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const base64String = reader.result.split(",")[1]; // Remove metadata

            const data =  {
                fileName: file.name,
                fileContent: base64String,
            }
            const response = await agreementSyncFileUpload(dealerId,data);

            if (response.status === 200) {
                setStatus(true);
                setLoading(false);
                setMsg("File uploaded successfully!.Please click on Close Button");
            } else {
                setStatus(false);
                setLoading(false);
                setMsg("Upload failed!");
            }
            setCloseBtn(false);
        };
                
      } catch (error) {
        console.log('upload error :',error);
        setStatus(false);
        setLoading(false);
        setMsg("Upload failed!");
        setCloseBtn(false);
      }
    };

  
    return (
      <React.Fragment>
        <Dialog
          open={uploadOpen}
          onClose={handleClose}
        >
          <DialogTitle>
             <Typography variant="subtitle1">Agreement Sync File Upload</Typography></DialogTitle>
          <DialogContent>
          <Input type="file" onChange={handleFileChange} disabled={loading||status}/>
          <Button onClick={handleUpload} disabled={loading || status}>Upload</Button>
            {loading && <Typography variant="subtitle2" color="info">Please wait while Uploading File....</Typography> }
            {msg && <Typography variant="subtitle2" color={status ? "primary":"error"} >{msg}</Typography> }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={closeBtn}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
}

export default AgreementSyncFileUpload